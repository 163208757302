import Image from 'next/image'
import { NextSeo } from 'next-seo'
import { Footer } from '@components/common'
import { Hero } from '@components/home'
import { BASE_URL } from '@lib/constants'

const stats = [
  { label: 'Founded', value: '2022' },
  { label: 'Employees', value: '1' },
  { label: 'Clients', value: '2' },
  { label: 'Raised', value: '$25M' },
]

const logos = [
  {
    name: 'HLB Electric LLC',
    url: '/images/companies/hlb-electric-logo-small.svg',
    website: 'https://www.hlbelectric.com/',
  },
  {
    name: 'Redshirt Sports',
    url: '/images/companies/RS_192.png',
    website: 'https://www.redshirtsports.xyz/',
  },
]

export default function Home() {
  return (
    <>
      <NextSeo canonical={BASE_URL} />
      <main>
        <Hero />
        {/* Testimonial/stats section */}
        <div className="relative mt-20">
          <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:items-start lg:gap-24 lg:px-8">
            <div className="relative sm:py-16 lg:py-0">
              <div
                aria-hidden="true"
                className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
              >
                <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl bg-gray-50 lg:right-72" />
                <svg
                  className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                  width={404}
                  height={392}
                  fill="none"
                  viewBox="0 0 404 392"
                >
                  <defs>
                    <pattern
                      id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={392}
                    fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                  />
                </svg>
              </div>
              <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
                {/* Testimonial card*/}
                <div className="relative overflow-hidden rounded-2xl pt-64 pb-10 shadow-xl">
                  <div className="absolute inset-0 h-full w-full object-cover">
                    <Image
                      src="/images/james_singleton_technavix_ceo.webp"
                      alt=""
                      layout="fill"
                      objectFit="cover"
                      objectPosition="top"
                    />
                  </div>
                  <div className="absolute inset-0 bg-gradient-to-t from-secondary-600 via-secondary-600 opacity-50" />
                  <div className="relative px-8">
                    <div>
                      <img
                        className="h-12"
                        src="/images/technavix-white.png"
                        alt="Technavix"
                      />
                    </div>
                    <blockquote className="mt-8">
                      <div className="relative text-lg font-medium text-white md:flex-grow">
                        <svg
                          className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-slate-900"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p className="relative">
                          Tincidunt integer commodo, cursus etiam aliquam neque,
                          et. Consectetur pretium in volutpat, diam. Montes,
                          magna cursus nulla feugiat dignissim id lobortis amet.
                        </p>
                      </div>

                      <footer className="mt-4">
                        <p className="text-base font-semibold text-white">
                          James Singleton, CEO at Technavix
                        </p>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
              {/* Content area */}
              <div className="pt-12 sm:pt-16 lg:pt-20">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  On a mission to empower small businesses
                </h2>
                <div className="mt-6 space-y-6 text-gray-500">
                  <p className="text-base leading-7">
                    Organizations need a partner that will solve their problems
                    for today and transform them to stay relevant with the
                    changing demands of the marketplace. Through strategy and
                    delivery of technology and engineering, platforming,
                    experience design and more, we help clients put consumers
                    first and stay agile—because what&apos;s good for your
                    customer is good for your business.
                  </p>
                </div>
              </div>

              {/* Stats section */}
              <div className="mt-10">
                <div className="mt-10">
                  <a
                    href="#"
                    className="text-base font-medium text-primary-500"
                  >
                    Learn more about how we&apos;re helping small
                    businesses&nbsp;&rarr;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Logo cloud section */}
        <div className="mt-32">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:items-center lg:gap-24">
              <div>
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  Trusted by businesses just like you building their digital
                  future
                </h2>
                <p className="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
                  Sagittis scelerisque nulla cursus in enim consectetur quam.
                  Dictum urna sed consectetur neque tristique pellentesque.
                  Blandit amet, sed aenean erat arcu morbi. Cursus faucibus nunc
                  nisl netus morbi vel porttitor vitae ut. Amet vitae fames
                  senectus vitae.
                </p>
                <div className="mt-6">
                  <a
                    href="#"
                    className="text-base font-medium text-primary-500"
                  >
                    Meet our investors and advisors&nbsp;&rarr;
                  </a>
                </div>
              </div>
              <div className="mt-12 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
                {logos.map((logo) => (
                  <a
                    key={logo.name}
                    className="col-span-1 flex justify-center bg-gray-50 py-8 px-8"
                    title={logo.name}
                    href={logo.website}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      src={logo.url}
                      alt={logo.name}
                      width={105}
                      height={105}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* CTA section */}
        <div className="relative mb-24 mt-24 sm:mb-12 sm:mt-32 sm:py-16">
          <div aria-hidden="true" className="hidden sm:block">
            <div className="absolute inset-y-0 left-0 w-1/2 rounded-r-3xl bg-gray-50" />
            <svg
              className="absolute top-8 left-1/2 -ml-3"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={392}
                fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
              />
            </svg>
          </div>
          <div className="relative mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
            <div className="overflow-hidden rounded-lg bg-primary-700 shadow-xl lg:grid lg:grid-cols-2 lg:gap-4">
              <div className="px-6 pt-10 pb-12 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                <div className="lg:self-center">
                  <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                    <span className="block">Ready to dive in?</span>
                    <span className="block">Start your free trial today.</span>
                  </h2>
                  <p className="mt-4 text-lg leading-6 text-primary-200">
                    Ac euismod vel sit maecenas id pellentesque eu sed
                    consectetur. Malesuada adipiscing sagittis vel nulla nec.
                  </p>
                  <a
                    href="#"
                    className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-primary-600 shadow hover:bg-primary-50"
                  >
                    Sign up for free
                  </a>
                </div>
              </div>
              <div className="aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1">
                <Image
                  className="translate-x-6 translate-y-6 transform rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                  src="https://images.unsplash.com/photo-1523726491678-bf852e717f6a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=600&q=80"
                  alt="App screenshot"
                  layout="fill"
                  objectFit="cover"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  )
}
