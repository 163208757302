import Link from 'next/link'
import Image from 'next/image'
import { DESCRIPTION } from '@lib/constants'
import styles from './Hero.module.css'

const Hero = () => (
  <div className="overflow-hidden pt-8 sm:pt-12 lg:relative lg:py-48">
    <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-24 lg:px-8">
      <div>
        <div>
          <Image
            className="h-11 w-auto"
            src="/images/technavix-color.png"
            alt="Technavix"
            height={495}
            width={1903}
            layout="responsive"
          />
        </div>
        <div className="mt-10">
          <div className="mt-6 sm:max-w-xl">
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Start creating your business&apos; digital future
            </h1>
            <p className="mt-6 text-xl text-gray-500">{DESCRIPTION}</p>
          </div>
          <div className="mt-10 text-center sm:flex sm:justify-center lg:justify-start">
            <div>
              <Link href="/contact">
                <a className="block w-full rounded-md border border-transparent bg-primary-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:px-10">
                  Get started
                </a>
              </Link>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <Link href="/work">
                <a className="flex w-full items-center justify-center rounded-md border border-transparent bg-secondary-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2 sm:px-10">
                  Our work
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
      <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <div className="hidden sm:block">
          <div className="absolute inset-y-0 left-1/2 w-screen rounded-l-3xl bg-gray-50 lg:left-80 lg:right-0 lg:w-full" />
          <svg
            className="absolute top-8 right-1/2 -mr-3 lg:left-0 lg:m-0"
            width={404}
            height={392}
            fill="none"
            viewBox="0 0 404 392"
          >
            <defs>
              <pattern
                id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={392}
              fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
            />
          </svg>
        </div>
        <div className={styles.heroRight}>
          <Image
            src="/images/ui_mockup.webp"
            alt="UI Mockup Drawing"
            width={990}
            height={660}
            priority
          />
        </div>
      </div>
    </div>
  </div>
)

export default Hero
